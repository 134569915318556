.ServicePlanTag {
  width: 100px;
  height: 30px;
  box-sizing: border-box;
  font-weight: 700;
  font-family: 'Pretendard-Regular';
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
//  letter-spacing: -0.05em;
  font-size: 14px;

  &.PREMIUM {
    border: 1px solid #10b2c7;
    background: linear-gradient(95.5deg, #10b2c7 16.29%, #0daf4b 84.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  &.AI_PLUS {
    color: #4991e4;// #1769c7;
    border: 1px solid #4991e4;//#1765bd;
  }

  &.ENGINEERING_PLUS {
    color: #48c754;//#29a735;
    border: 1px solid #48c754;//#29a735;
  }

  &.STANDARD {
    color: #f0aa20;
    border: 1px solid #f0aa20;
  }


  &.EDUCATION {
    color: rgb(246, 116, 104);//#ff4f3e;
    border: 1px solid rgb(246, 116, 104);// #ff4f3e;
  }
}