.UserList {
  color: #495057 !important;
  font-size: 0.9rem;
  .MuiTableRow-root {
    height: 60px;
  }
  // table head
  .th {
    font-weight: bold;
    font-size: 15px;
    font-family: 'Pretendard-Regular';
    border-bottom-color: #eff0f2;
    height: 50px;
    color: #495057;
   // background-color: #f5f6f8;
  }
  .td {
    color: #515357;
    font-family: 'Pretendard-Regular';
    font-size: 14px;
    .MuiInputBase-root {
      font-family: inherit;
    }

    &.delete-btn {
      color: #515357;
      .icon {
        width: 20px;
      }
      .disabled {
        cursor: default;
        pointer-events: none;
        color: #ccc;
      }
    }
  }

  .more-menu {
    font-family: 'Pretendard-Regular';
    
  }

  .edit-count-btn, .reset-btn {
    color: #515357;
    border-radius: 6px;
    border: 1px solid #F2F4F5;
    background-color: #F9F9FB;
    &:hover {
      color: #4052b3;
      background-color: #f1f1ff;
      
    }
  }
  .wrap-info {
    display: flex;
    .text {
      text-overflow: ellipsis;
      width: 100px;
      overflow: hidden;
      white-space: nowrap;
    }

    .contact-icon,
    .business-icon {
      width: 14px;
      color: #999;
      margin-right: 5px;
    }
  }

  .wrap-id {
    display: flex;
    align-items: center;
//    flex-direction: column;
    .ellipsis {
      display: block;
      width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

    }

    .clipboard {
      border: none;
      background-color: transparent;
    }

    .copy-icon {
      width: 16px !important;
      height: 16px !important;
      cursor: pointer;
      color: #999;

      &:hover {
        color: #232732;
      }
    }

  }

  .dropdown-title {
    .email {
      font-weight: bold;
      font-size: 14px;
    }
    position: relative;
    cursor: pointer;
    .dropdown-content {
      display: none;
      z-index: 1;
      position: absolute;
      border-radius: 4px;
      width: 210px;
      background: #707070;
      color: white;
      padding: 8px;
      margin-top: 10px;
    }

    &:hover {
      .dropdown-content {
        display: block;
      }
    }
  }

  .period {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    .wave {
      display: flex;
      margin-right: 10px;
      align-items: center;
    }
  }
  
  .pagination {
    width: 100%;
    display: flex !important;
    justify-content: flex-end;
  }
}
.MuiMenuItem-root {
  font-family: 'Pretendard-Regular' !important;
  .del-icon {
    width: 18px;
    margin-right: 5px;    
  }
  .del-order-menu {
    color: #4052b3;
  }
  .del-subscribe-menu {
    color: #dc143c;
  }
}
