.DatePicker {
  &.MuiFormControl-marginNormal {
    // width: fit-content;
  }

  .MuiInputBase-input {
     width: 90px;
  }
  .MuiIconButton-root {
    padding: 4px;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }
}