.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.MuiButton-root.refund-btn {
  height: 20px;
  width: 40px;
  min-width: 40px;
  padding: 0px;
  float: right;
  background-color: crimson;
  color: white;
}

.paying-type-td {
  position: relative;
}
.infinity-switch {
  position: absolute !important;
  top: -2px !important;
}

.user-table .MuiTableCell-sizeSmall {
  padding: 0px;
}
.user-table .MuiFormControl-marginNormal {
  margin: 0px;
  width: 150px;
}